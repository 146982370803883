document.addEventListener("DOMContentLoaded", function () {
  document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
      e.preventDefault();

      document.querySelector(this.getAttribute('href')).scrollIntoView({
        behavior: 'smooth'
      });
    });
  });

  const close = document.querySelector('#close');
  close.addEventListener("click", function() {
    let elem = document.querySelector("#hamburger");
    elem.classList.remove("flex");
    elem.classList.add("hidden");
  });

  const hamb = document.querySelector('#menuicon');
  hamb.addEventListener("click", function() {
    let elem = document.querySelector("#hamburger");
    elem.classList.add("flex");
    elem.classList.remove("hidden");
  })
});
